import React from 'react';
import { useParams } from "react-router-dom";

import MediaPlayer from '../components/MediaPlayer.jsx';
import ZoomableImage from '../components/ZoomableImage.jsx';
import { getThought } from '../data.js'

export default function Thought() {    
    const params = useParams();
    const thought = getThought(params.thoughtId);

    const indicatorRegex = new RegExp('({{.+ .+}})', 'mg');
    const imageRegex = new RegExp('{{image (.+) "(.*)"}}', '');
    const audioRegex = new RegExp('{{audio (.+) "(.+)"}}', '');
    const headerRegex = new RegExp('{{header "(.+)"}}', '');
    const embedRegex = new RegExp('{{embed "(.+)"}}', '');
    const splits = thought.content.split(indicatorRegex);
    const out = [];
    splits.forEach((el, idx) => {
        if (!indicatorRegex.test(el)) {
            out.push(<p key={idx} dangerouslySetInnerHTML={{ __html: el }} />)
            return;
        }

        if (imageRegex.test(el)) {
            const img = el.match(imageRegex);
            const url = img[1];
            const caption = img[2];
            const markup = <ZoomableImage key={idx} smallImageSrc={url} smallImageClass={"ne-thought-image"} divStyle={{textAlign: 'center'}} />;
            out.push(markup);
            if (caption) {
                out.push(<p className={"ne-caption ne-center-text"} dangerouslySetInnerHTML={{ __html: caption }} />)
            }
        } else if (audioRegex.test(el)) {
            const audio = el.match(audioRegex);
            const markup = <MediaPlayer key={idx} className={'ne-thought-audio'} track={audio[1]} title={audio[2]} />;
            out.push(markup);
        } else if (headerRegex.test(el)) {
            const headerText = el.match(headerRegex)[1];
            const markup = <h2>{headerText}</h2>;
            out.push(markup);
        } else if (embedRegex.test(el)) {
            const embedText = el.match(embedRegex)[1];
            const markup = <div className="ne-center ne-thought-embed" dangerouslySetInnerHTML={{ __html: embedText }} />;
            out.push(markup);
        }
    })

    return (
        <div className='ne-thought ne-center'>
            <h1>{thought.title}</h1>
            {thought.subtitle && 
            <h5 className='ne-thought-subtitle'>
                    {thought.subtitle}
            </h5>}
            <p>{thought.date}</p>
            <div className='ne-justify-text ne-thought-content'>
                {out}
            </div>
        </div>
    )
};