import React from 'react';
 
class ZoomableImage extends React.Component {
    constructor() {
        super();
        this.state = {
            modalIsOpen: false
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal() {
        this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
    }
    
    render() {
        let { smallImageSrc, largeImageSrc, smallImageClass, divStyle, height, width } = this.props;
        if (!largeImageSrc) {
            largeImageSrc = smallImageSrc;
        }
        const style = {
            maxHeight: height,
            maxWidth: width
        };
        const smallImageClasses = smallImageClass + ' ne-zoomable-image';
        return (
            <div style={divStyle}>
                <img 
                    onClick={this.toggleModal} 
                    className={smallImageClasses} 
                    src={smallImageSrc} 
                    alt="Night Errands" 
                    style={style}
                />
            </div>
        )
    }
  }

export default ZoomableImage;