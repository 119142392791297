import React from 'react';

import ThoughtBrief from '../components/ThoughtBrief';
import { getThoughts } from '../data.js';

export default function Thoughts() {
    const thoughts = getThoughts();
    thoughts.sort(function(a, b) {
      const nameA = a.id.toUpperCase(); // ignore upper and lowercase
      const nameB = b.id.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }

      // names must be equal
      return 0;
    });
    function renderThought(t) {
        return (
            <div key={t.id} className="ne-thought">
                <ThoughtBrief thought={t}/>
            </div>
        )
    }
    
    return (
        <div className="ne-thoughts">
            {thoughts.map(t => renderThought(t))}
        </div>
    )
};