import React from 'react';
import { Link, useParams } from "react-router-dom";

import { getArtist, getReleases } from '../data.js'

import ZoomableImage from '../components/ZoomableImage.jsx';
import ReleaseBrief from '../components/ReleaseBrief';

export default function Artist() {
    const params = useParams();
    const artist = getArtist(params.artistId);
    const releases = getReleases();

    function renderFeaturedReleases() {
        const releaseIds = artist.featured_releases;
        const featureReleases = Object.values(releases).filter(release => releaseIds.includes(release.id));
        if (featureReleases) {
            return (
                <div className='ne-artist-detail-featured-releases'>
                    {featureReleases.map(release => 
                        <Link to={`/releases/${release.id}`} className='ne-center '>
                            <ReleaseBrief
                                key={release.id} 
                                release={release} 
                                hideArtist={true}    
                            />
                        </Link>
                    )}
                </div>
            );
        }
    };

    return (
        <div className="ne-artist-detail ne-center">
            <div className="ne-artist-image">
                <ZoomableImage
                    largeImageSrc={artist.image}
                    smallImageSrc={artist.image}
                    width={200}
                    height={200}
                />
            </div>
            <div>
                <div className="ne-center-text">
                    <b>{artist.name}</b>
                </div>
                <div className='ne-justify-text'>
                    {artist.description}
                </div>
                {renderFeaturedReleases()}
            </div>
        </div>
    )
};