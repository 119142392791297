import React from 'react';
import ZoomableImage from './ZoomableImage.jsx';

class Footer extends React.Component {    
    toggleStars() {
        if (document.body.style.backgroundImage === "") {
            document.body.style.backgroundImage = "url('https://nighterrands.s3.amazonaws.com/images/stars.gif')";
        } else {
            document.body.style.backgroundImage = "";
        }
    }

    render() {
        const jellys = [
            { src: "https://nighterrands.s3.amazonaws.com/images/logo.png" },
            { src: "https://nighterrands.s3.amazonaws.com/images/jellys/purple.png" },
            { src: "https://nighterrands.s3.amazonaws.com/images/jellys/orange.png" },
            { src: "https://nighterrands.s3.amazonaws.com/images/jellys/moon.png" },
            { src: "https://nighterrands.s3.amazonaws.com/images/jellys/tiki.png" },
            { src: "https://nighterrands.s3.amazonaws.com/images/jellys/jelly-multi-2.png" },
        ]
        return (
            <div className='ne-footer-wrap'>
                <div className='ne-footer-spacer' />
                <div className="ne-footer">
                    <div className="spacer"/>
                    <img className='ne-stars' src={"https://nighterrands.s3.amazonaws.com/images/telescope.png"} onClick={() => this.toggleStars()} alt="night" height="20" width="20"/>
                    <ZoomableImage
                        images={jellys}
                        smallImageSrc={"https://nighterrands.s3.amazonaws.com/images/logo-small.png"}
                        width={25}
                        height={25}
                    />
                    <a href="https://www.instagram.com/nighterrands" target="_blank" rel="noopener noreferrer"><img src={"https://nighterrands.s3.amazonaws.com/images/ig_logo.png"} alt="Insta" height="20" width="20"/></a>
                    <a href="https://nighterrands.bandcamp.com/releases" target="_blank" rel="noopener noreferrer"><img src={"https://nighterrands.s3.amazonaws.com/images/bc_logo.png"} alt="Bandcamp" height="20" width="20"/></a>
                    <div className="spacer"/>
                </div>
            </div>
        )
    }
  }

export default Footer;