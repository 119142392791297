import React from 'react';
import { Link } from "react-router-dom";

import { getReleases } from '../data.js'
import ReleaseBrief from '../components/ReleaseBrief';

export default function Releases() {
    const releases = getReleases();

    function renderSection(section) {
        const sectionReleases = releases.reverse()
            .filter(r => r.section === section)
            .map(r => {
                return (
                    <Link to={`/releases/${r.id}`} key={r.id} className='ne-center'>
                        <ReleaseBrief key={r.id} release={r} />
                    </Link>
                );
            });
        return (
            <div key={section} className="ne-release-section">
                <div className="ne-release-section-title">
                    {section}
                </div>
                {sectionReleases}
            </div>    
        );
    }
    
    function renderReleases() {
        const sections = Object.values(releases).map(release => release.section);
        const distinctSections = [...new Set(sections)].sort();
        return distinctSections.map(section => renderSection(section));
    }
    
    return (
        <div className="ne-releases ne-center">
            {renderReleases()}
        </div>
    )
}