import React from 'react';

import { getBio } from '../data.js';
import ZoomableImage from '../components/ZoomableImage.jsx';

export default function About() {    
    function renderBio(name, email, gram) {
        const bio = getBio(name)
        return (
            <div className='ne-bio'>
                <div className='ne-bio-name'>
                    {name}
                </div>
                <span>
                    {gram && <a className="ne-bio-gram ne-link" href={gram} target="_blank" rel="noopener noreferrer"><img src={"https://nighterrands.s3.amazonaws.com/images/ig_logo.png"} alt="Insta" height="15" width="15"/></a>}
                    {email && <a className="ne-bio-email ne-link" href={"mailto:" + email}>{email}</a>}
                </span>
                <div className='ne-bio-image'>
                    <ZoomableImage
                        smallImageSrc={bio.image.small}
                        largeImageSrc={bio.image.full}
                        width={150}
                        height={150}
                    />
                </div>
                <div className='ne-bio-desc ne-center-text'>
                    {bio.bio}
                </div>
            </div>
        );
    }

    return (
        <div className="ne-about">
            nighterrands is...
            {renderBio('leo', 'leo@nighterrands.com', 'https://www.instagram.com/leofromvermont/')}
            {renderBio('joe', 'joe@nighterrands.com', 'https://www.instagram.com/cosmic.joe/')}
            {renderBio('mike')}
            {renderBio('tyler', '', 'https://www.instagram.com/siamesefaithhealersnetwork/')}
            {renderBio('julie', '', 'https://www.instagram.com/juliev.t/')}
            <div className="ne-about-general">
                Duxbury to DC. Still VT.
            </div>
        </div>
    )
}