import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter, Routes, Route } from "react-router-dom";

import Layout from './components/Layout';
import NightErrands from './pages/Home';
import Releases from './pages/Releases.jsx';
import Release from './pages/Release.jsx';
import Artists from './pages/Artists.jsx';
import Artist from './pages/Artist.jsx';
import Zines from './pages/Zines.jsx';
import Zine from './pages/Zine.jsx';
import Fugues from './pages/Fugues.jsx';
import Thoughts from './pages/Thoughts.jsx';
import Thought from './pages/Thought.jsx';
import About from './pages/About.jsx';

import './NightErrands.css';

import * as serviceWorker from './serviceWorker';

function withLayout(children) {
	return (
		<Layout>
			{children}
		</Layout>
	)
};

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(<HashRouter>
    <Routes>
      <Route path="/" element={withLayout(<NightErrands />)} />
        <Route path="releases" element={withLayout(<Releases />)} />
        <Route path="releases/:releaseId" element={withLayout(<Release />)} />
      <Route path="artists" element={withLayout(<Artists />)} />
      <Route path="artists/:artistId" element={withLayout(<Artist />)} />
      <Route path="zines" element={withLayout(<Zines />)} />
      <Route path="zines/:zineId" element={withLayout(<Zine />)} />
      <Route path="fugues" element={withLayout(<Fugues />)} />
      <Route path="thoughts" element={withLayout(<Thoughts />)} />
      <Route path="thoughts/:thoughtId" element={withLayout(<Thought />)} />
      <Route path="about" element={withLayout(<About />)} />
        <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>There's nothing here!</p>
          </main>
        }
      />
      </Routes>
</HashRouter>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
