import React from 'react';
import { Link } from "react-router-dom";

import { getZines } from '../data.js'
import ZineBrief from '../components/ZineBrief';

export default function Zines(props) {
    function renderZine(zine) {
        return (
            <Link to={`/zines/${zine.id}`} key={zine.id} className='ne-link ne-zine-preview ne-center'>
                <ZineBrief key={zine.id} zine={zine} />
            </Link>
        );
    }

    return (
        <div className="ne-zines">
            {getZines().map(z => renderZine(z))}
        </div>
    )
}