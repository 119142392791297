import React, { useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import MediaPlayer from './MediaPlayer';

function Layout(props) {
   const [nowPlaying, setNowPlaying] = useState("NE003");

   return(
      <div className='ne-layout-wrapper'>
         <div className='ne-content'>
            <Header />
            { React.cloneElement(props.children, { setNowPlaying: setNowPlaying }) }
         </div>
         <Footer />
         <MediaPlayer releaseId={nowPlaying} />
      </div>
   );
}

export default Layout;