import React from 'react';

import Release from './Release';

export default function Home(props) {  
  return (
    <div>
        <Release releaseId={"NE003"} setNowPlaying={props.setNowPlaying} />
    </div>
  );
};