import React from 'react';
import { useParams } from "react-router-dom";

import { getZine } from '../data.js'
import ZoomableImage from '../components/ZoomableImage.jsx';

const width = window.innerWidth > 700 ? 480 : 300;

export default function Zine() {    
    const params = useParams();
    const zine = getZine(params.zineId);
    const divStyle = {
        alignSelf: 'center'
    };
    const pagesList = zine.pages.map(page => {
        return {src: page.full, key: page.full}
    });

    return (
        <div className='ne-zine-detail'>
            {zine.pages.map((page, i) => 
                <ZoomableImage 
                    key={i}
                    smallImageSrc={page.small ? page.small : page.full} 
                    largeImageSrc={page.full}
                    images={pagesList}
                    index={i}
                    width={width}
                    divStyle={divStyle}
                    smallImageClass='ne-zine-page-image-scrollable'
                />
            )}
        </div>
    )
};