import React from 'react';
import ArtistBrief from '../components/ArtistBrief';
import { Link } from "react-router-dom";

import { getArtists } from '../data.js'

export default function Artists() {
    let artists = getArtists();

    function renderArtist(a) {
        return (
            <Link to={`/artists/${a.id}`} key={a.id}>
                <ArtistBrief key={a.id} artist={a} />
            </Link>
        );
    }
    
    return (
        <div className="ne-artists">
            {artists.map(a => renderArtist(a))}
        </div>
    )
}