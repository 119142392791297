import React from 'react';
// import { MediaPlayer } from '@cassette/player';
// import '@cassette/player/dist/css/cassette-player.css';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import { getRelease } from '../data.js'

export default function Playlist(props) {
    const playlist = [];
    if (props.track) {
      playlist.push({src: props.track, title: props.title});
    } else {
      const releaseId = props.releaseId ? props.releaseId : "NE003";
      const release = getRelease(releaseId);
      if (release.audio && release.audio.length > 0) {
        if (typeof release.audio[0] === "string") {
          release.audio && release.audio.forEach((audioFile, i) => playlist.push({src: audioFile, title: `${release.name} - ${i + 1}`}));
        } else if (typeof release.audio[0] === "object") {
          release.audio && release.audio.forEach((audioFile, i) => playlist.push({src: audioFile.file, title: `${release.name} - ${audioFile.title}`}));
        }
      }
    }

    const [currentTrack, setTrackIndex] = React.useState(0)
    if (playlist.length <= 0) {
      return;
    }

    const handleClickNext = () => {
        console.log('click next')
          setTrackIndex((currentTrack) =>
              currentTrack < playlist.length - 1 ? currentTrack + 1 : 0
          );
      };
    
    const handleEnd = () => {
      console.log('end')
      setTrackIndex((currentTrack) =>
              currentTrack < playlist.length - 1 ? currentTrack + 1 : 0
          );
    }

    return (
      <div className={`${props.className} ne-audio-player-wrapper`}>
        <AudioPlayer
          volume="0.5"
          src={playlist[currentTrack].src}
          showSkipControls
          onClickNext={handleClickNext}
          onEnded={handleEnd}
        />
      </div>
    );
}