import React from 'react';
import { Link } from "react-router-dom";

import ZoomableImage from './ZoomableImage.jsx';

export default function ThoughtBrief(props) {
    const thought = props.thought;
    const str = thought.content;
    const indicatorRegex = new RegExp('({{.+ .+}})', 'mg');
    let content = str.replace(indicatorRegex, '').trim();
    content = (content.length > 800) ? content.substr(0, 800-1) + '...' : content;

    function renderContent() {
        return (
            <div className='ne-thought-content-preview ne-justify-text' dangerouslySetInnerHTML={{ __html: content }} />
        );
    }
    return (
        <div className="ne-thought-brief">
            <div>
                <Link to={`/thoughts/${thought.id}`} className='ne-link'>
                    <div className='ne-thought-title'>
                            {thought.title}
                    </div>
                    {thought.subtitle && 
                    <div className='ne-thought-subtitletitle'>
                            {thought.subtitle}
                    </div>}
                </Link>
                <div className='ne-thought-date'>
                    {thought.date}
                </div>
            </div>
            <div>
                <ZoomableImage
                    smallImageSrc={thought.coverImage.small}
                    largeImageSrc={thought.coverImage.full}
                    smallImageClass="ne-thought-cover-image"
                />
                {renderContent()}
            </div>
        </div>
    )
};