import React, { useState } from 'react';

import ZoomableImage from '../components/ZoomableImage.jsx';

const width = window.innerWidth > 700 ? 480 : 320;

export default function Fugues() {
    const min = 1;
    const max = 48;
    const [index, setIndex] = useState(1);

    const src = `https://nighterrands.s3.amazonaws.com/images/random/${index}.jpg`;
    return (
        <div className='ne-center-text'>
            <div className='ne-fugue-next' onClick={() => setIndex(Math.floor(Math.random() * (max - min + 1)) + min)}>{'>'}</div>
            <ZoomableImage
                width={width}
                smallImageSrc={src}
                largeImageSrc={src}
                smallImageClass="ne-fugue-image"
            />
        </div>
    )
};