import React from 'react';
import { Link, useParams } from "react-router-dom";

import { getRelease, getZine } from '../data.js'
import ZoomableImage from '../components/ZoomableImage.jsx';
import ZineBrief from '../components/ZineBrief.jsx';

export default function Release(props) {
    const params = useParams();
    const releaseId = props.releaseId ? props.releaseId : params.releaseId;
    const release = getRelease(releaseId);

    const playlist = [];
    release.audio && release.audio.forEach(audioFile => playlist.push({url: audioFile}));

    return (
        <div className="ne-release-detail">
            <div className="ne-release-left">
                <div className="ne-release-image">
                    <ZoomableImage
                        largeImageSrc={release.image}
                        smallImageSrc={release.image_preview}
                        width={200}
                        height={200}
                    />
                </div>
                </div>  
                    <div className="ne-release-details">
                        <div className="ne-release-detail-header">
                            <div className="ne-release-detail-name">
                            <b>{release.name}</b>
                        </div>
                        <div className="ne-release-detail-artist">
                        <i>{release.artist}</i>
                    </div>
                    <div className='ne-release-detail-date'>
                        {release.date}
                    </div>
                    {release.audio && release.audio.length > 0 && <div className="ne-release-detail-play-button" onClick={() => props.setNowPlaying(releaseId)}>~ listen ~</div>}
                    {release.bandcamp_url && release.bandcamp_url.length > 0 && <a className="ne-release-detail-purchase-button" href={release.bandcamp_url} target="_blank" rel="noopener noreferrer">~ purchase ~</a>}
                    {release.shopifyLink && <a className="ne-release-detail-purchase-button" href={release.shopifyLink} target="_blank" rel="noopener noreferrer">~ purchase ~</a>}
                </div>  
                <div className='ne-release-detail-description ne-justify-text' dangerouslySetInnerHTML={{__html: release.description}} />
                    {release.zine && (
                        <div className='ne-release-detail-zine'>
                            <Link to={`/zines/${release.zine}`} className='ne-link ne-center'>
                                <ZineBrief zine={getZine(release.zine)} />
                            </Link>
                        </div>
                    )}
            </div>
        </div>
    );

}