import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Header() {
    const location = useLocation();
    const pathname = location.pathname;

    function getClasses(tab) {
        return `/${tab}` === pathname ? 'ne-current-tab': 'ne-link';
    }

    function getMainClasses(tab) {
        return `/${tab}` === pathname ? 'ne-current-tab ne-xx-large': 'ne-link ne-xx-large';
    }

    return (
        <div className='ne-header'>
            <span>
                <Link to='/' className={getMainClasses('')}>nighterrands</Link>
            </span>
            <div>
                <Link to='/'>
                    <img 
                        className='ne-logo' 
                        src={'https://nighterrands.s3.amazonaws.com/images/logo-small.png'} 
                        alt='Night Errands' 
                    />
                </Link>
            </div>
            <ul className='ne-header-list'>
                <li>
                    <Link to='/releases' className={getClasses('releases')}>releases</Link>
                </li>
                <li>
                    <Link to='/artists' className={getClasses('artists')}>artists</Link>
                </li>
                <li>
                    <Link to='/zines' className={getClasses('zines')}>zines</Link>
                </li>
                <li>
                    <Link to='/fugues' className={getClasses('fugues')}>fugues</Link>
                </li>
                <li>
                    <Link to='/thoughts' className={getClasses('thoughts')}>thoughts</Link>
                </li>
                <li>
                    <Link to='/about' className={getClasses('about')}>about</Link>
                </li>
            </ul>
        </div>
    )
  }

export default Header;